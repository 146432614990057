<template>
  <div>
    <v-row>
      <v-col>
        <h1>Tätigkeiten</h1>
      </v-col>
      <v-col md="5" s="12">
        <v-select
          solo
          flat
          dense
          hide-details
          v-model="selectedHeaderValues"
          :items="headers"
          label="Spalten"
          multiple
        >
          <template v-slot:selection="{ index }">
            <template v-if="index === 0">
              Spalten
            </template>
          </template>
        </v-select>
      </v-col>
    </v-row>

    <div v-if="$route.query.customer_id" class="mb-1">
      <span class="subheading">Filter:</span>
      <v-chip
        class="ma-2"
        close
        @click:close="
          $router.push({ name: 'Tasks' });
          load();
        "
      >
        Kunde
      </v-chip>
    </div>
    <v-data-table
      :footer-props="{
        'items-per-page-options': [10, 50, 100]
      }"
      :headers="selectedHeaders"
      :options.sync="options"
      :server-items-length="total"
      :items="tasks"
      item-key="id"
      class="elevation-1 tasks"
      :loading="loading || saving"
    >
      <template v-slot:item.name="props">
        <div class="editable" @click.stop.prevent="">
          <input
            style="width: 20em;"
            type="text"
            :value="props.item.name"
            @blur="
              e => {
                props.item.name !== e.target.value
                  ? update({ ...props.item, name: e.target.value })
                  : null;
              }
            "
          />
        </div>
      </template>
      <template v-slot:item.description="props">
        <div class="editable" @click.stop.prevent="">
          <input
            style="width: 22em;"
            type="text"
            :value="props.item.description"
            @blur="
              e => {
                props.item.description !== e.target.value
                  ? update({ ...props.item, description: e.target.value })
                  : null;
              }
            "
          />
        </div>
      </template>
      <template v-slot:item.hourly_rate="props">
        <div class="editable" @click.stop.prevent="">
          <input
            :style="{ color: props.item.hourly_rate ? 'null' : 'grey' }"
            placeholder="0"
            style="width: 6em;"
            type="number"
            :value="props.item.hourly_rate"
            @blur="
              e => {
                props.item.hourly_rate !== e.target.value
                  ? update({ ...props.item, hourly_rate: e.target.value })
                  : null;
              }
            "
          />
        </div>
      </template>
      <template v-slot:item.customer_id="props">
        <div class="editable" @click.stop.prevent="">
          <select
            :style="{ color: props.item.customer && props.item.customer.color }"
            type="text"
            v-model="props.item.customer_id"
            @change="
              e => {
                props.item.name !== e.target.value
                  ? update({ ...props.item })
                  : null;
              }
            "
          >
            <option
              :style="{ color: customer.color }"
              :value="customer.id"
              v-for="customer in customers"
              :key="customer.id"
              >{{ customer.name }}</option
            >
          </select>
        </div>
      </template>
      <template v-slot:item.duration="props">
        <router-link
          v-if="props.item.duration"
          :to="{
            name: 'Tracks',
            query: { task_id: props.item.id }
          }"
          >{{ formatSeconds(props.item.duration) }}
        </router-link>
      </template>
      <template v-slot:item.earnings="props">
        <router-link
          v-if="props.item.earnings"
          :to="{
            name: 'Tracks',
            query: { task_id: props.item.id }
          }"
          >{{ formatEarnings(props.item.earnings) }} €
        </router-link>
      </template>
      <template v-slot:item.total_duration="props">
        <router-link
          v-if="props.item.total_duration"
          :to="{
            name: 'Tracks',
            query: { task_id: props.item.id }
          }"
          >{{ formatSeconds(props.item.total_duration) }} €
        </router-link>
      </template>
      <template v-slot:item.total_earnings="props">
        <router-link
          v-if="props.item.total_earnings"
          :to="{
            name: 'Tracks',
            query: { task_id: props.item.id }
          }"
          >{{ formatEarnings(props.item.total_earnings) }} €
        </router-link>
      </template>
      <template v-slot:item.actions="props">
        <v-btn icon @click="deleteTask(props.item)"
          ><v-icon>delete</v-icon></v-btn
        >
      </template>
    </v-data-table>
    <v-btn class="mt-5" @click="exportData()">Export</v-btn>
  </div>
</template>

<style scoped lang="scss">
.tasks {
  a {
    text-decoration: none;
    white-space: nowrap;
  }
}
</style>

<script>
import FileDownload from "js-file-download";

export default {
  data() {
    return {
      options: {
        itemsPerPage: 10,
        sortBy: ["earnings"],
        sortDesc: [true]
      },
      total: 0,
      customers: [],
      loadingCustomers: false,
      saving: false,
      loading: true,
      tasks: [],
      selectedHeaderValues: [
        "customer_id",
        "name",
        "description",
        "hourly_rate",
        "duration",
        "earnings"
      ],
      headers: [
        {
          text: "Kunde",
          align: "start",
          sortable: true,
          value: "customer_id"
        },
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: "name"
        },
        {
          text: "Beschreibung",
          align: "start",
          sortable: false,
          value: "description"
        },
        {
          text: "Stundensatz",
          align: "start",
          sortable: false,
          value: "hourly_rate"
        },
        {
          text: "Gesamte Arbeitszeit",
          align: "start",
          sortable: true,
          value: "total_duration"
        },
        {
          text: "Gesamte Einnahmen",
          align: "end",
          sortable: true,
          value: "total_earnings"
        },
        {
          text: "Offene Arbeitszeit",
          align: "start",
          sortable: true,
          value: "duration"
        },
        {
          text: "Offene Einnahmen",
          align: "end",
          sortable: true,
          value: "earnings"
        }
      ]
    };
  },
  mounted() {
    this.tasks = this.$store.getters["tasks/list"];
    this.total = this.$store.getters["tasks/listTotal"];
    this.customers = this.$store.getters["customers/list"];
    this.loadCustomers();
  },
  watch: {
    filter: {
      handler() {
        this.load();
      },
      deep: true
    },
    options: {
      handler() {
        this.load();
      },
      deep: true
    },
    current_track: {
      deep: true,
      handler(track, old_track) {
        if (track.id !== old_track.id) {
          this.load();
        }
      }
    }
  },
  computed: {
    selectedHeaders() {
      const selectedHeaders = this.headers.filter(h =>
        this.selectedHeaderValues.some(sh => sh === h.value)
      );
      selectedHeaders.push({
        text: "",
        align: "end",
        sortable: true,
        value: "actions"
      });
      return selectedHeaders;
    },
    current_track() {
      return this.$store.getters["tracks/current"];
    },
    filter() {
      let filter = { with_hours: true };
      if (this.$route.query.customer_id) {
        filter.customer_id = this.$route.query.customer_id;
      }
      if (
        this.selectedHeaderValues.includes("total_duration") ||
        this.selectedHeaderValues.includes("total_earnings")
      ) {
        filter.with_total_hours = true;
      }
      return filter;
    }
  },
  methods: {
    exportData() {
      return this.axios
          .get('/api/v1/tracks/export', {
            responseType: "blob",
            params: {
              customer_id: this.$route.query.customer_id
            }
          })
          .then(response => {
            FileDownload(response.data, "aufzeichnungen.xlsx");
          });
    },
    load() {
      this.loading = true;
      this.$store
        .dispatch("tasks/list", {
          page: { number: this.options.page, size: this.options.itemsPe1rPage },
          sort: this.options.sortBy.length
            ? (this.options.sortDesc[0] ? "-" : "") +
              this.options.sortBy.join(",")
            : null,
          filter: this.filter,
          include: "customer"
        })
        .then(() => {
          this.total = this.$store.getters["tasks/listTotal"];
          this.tasks = this.$store.getters["tasks/list"];
          this.loading = false;
        });
    },
    restart(task) {
      this.$store.dispatch("tracks/add", {
        type: "tracks",
        task_id: task.id,
        start: this.$date(new Date(), "yyyy-MM-dd HH:mm:ss")
      });
    },
    update(task) {
      this.saving = true;
      task.hourly_rate = parseInt(task.hourly_rate);
      this.$store.dispatch("tasks/update", task).then(() => {
        this.saving = false;
        this.$store.dispatch("alerts/success", "Gespeichert");
        this.load();
      });
    },
    deleteTask(task) {
      this.$swal
        .fire({
          title: task.name + " löschen?",
          text: "Alle zugewiesenen Zeiteinträge werden gelöscht.",
          showCancelButton: true,
          confirmButtonText: `Löschen`,
          cancelButtonText: `Abbrechen`
        })
        .then(result => {
          if (result.isConfirmed) {
            this.$store
              .dispatch("tasks/destroy", task.id)
              .then(() => {
                this.load();
              })
              .catch(() => {
                this.$store.dispatch(
                  "alerts/error",
                  "Tätigkeit konnte nicht gelöscht werden."
                );
              });
          }
        });
    },
    loadCustomers() {
      this.debounce(() => {
        this.loadingCustomers = true;
        this.$store
          .dispatch("customers/list", {
            page: { number: 1, size: 200 },
            sort: "name"
          })
          .then(() => {
            this.customers = this.$store.getters["customers/list"];
            this.loadingCustomers = false;
          });
      }, 500);
    }
  }
};
</script>
